import {LayoutTypes} from 'ts/enums/layout'

// ** ThemeConfig Import
import themeConfig from 'configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

interface ILayout {
  isRTL:any ,
  menuCollapsed:any ,
  menuHidden:any ,
  contentWidth: any
}

const initialState:ILayout = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action:any) => {
  switch (action.type) {
    case LayoutTypes.HANDLE_CONTENT_WIDTH:
      return { ...state, contentWidth: action.value }
    case LayoutTypes.HANDLE_MENU_COLLAPSED:
      window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    case LayoutTypes.HANDLE_MENU_HIDDEN:
      return { ...state, menuHidden: action.value }
    case LayoutTypes.LOGOUT:
      return initialState
    default:
      return state
  }
}
