import {NoteTypes} from 'ts/enums/note'
import { INote } from 'ts/interfaces/note'

interface INotesReducer {
  data: INote[],
  total: number,
  loading: boolean
}

const initialState:INotesReducer = {
  data: [],
  total: 0,
  loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case NoteTypes.LIST_NOTES:
    return {...state, data: action.data.notes, total: action.data.total}
  case NoteTypes.SET_LOADING_NOTES:
    return {...state, loading: action.data}
  case NoteTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}