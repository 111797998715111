import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { API as BASE_URL } from "utils/constants"
import { getValueLocalStorage } from 'utils/storage'

interface IFetch {
  path: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: Object
  params?: any
  headers?: Object | any
  hasToken?: boolean
}

const instance:any = axios.create({
  baseURL: BASE_URL
})

instance.interceptors.request.use(
  (config: any) => {
    if (isTokenExpired()) {
      return window.location.href = '/login?expired=1'
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

function isTokenExpired() {
  const token = localStorage.getItem('token')

  if (token) {
    const decodedToken: any = jwt_decode(token)

    if (decodedToken && decodedToken.exp) {
      const currentTime = Math.floor(Date.now() / 1000)
      if (decodedToken.exp < currentTime) {
        return true // El token ha expirado
      }
    }
  }

  return false // El token no ha expirado
}

class API {
  static request = async ({
    method = 'GET',
    hasToken = false,
    path,
    body,
    params,
    headers,
  }: IFetch) => {
    const response = instance(
      {
        url: path,
        headers: {
          ...(hasToken && {
            Authorization: `Bearer ${getValueLocalStorage('token')}`
          }),
          ...headers,
        },
        params,
        data: body,
        method,
      }
    )
    .then((response: any) => {
      return response?.data || response
    })
    .catch((error: any) => {
      return error?.response?.data || error
    })

    return response
  }
}

export default API