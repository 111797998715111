import { GiftCardsTypes } from "ts/enums/giftCards";
import { IGiftCard } from "ts/interfaces/giftCards";

interface IGiftCardsReducer {
  data: IGiftCard[];
  total: number;
  loading: boolean;
}

const initialState: IGiftCardsReducer = {
  data: [],
  total: 0,
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch (action.type) {
    case GiftCardsTypes.LIST_GIFT_CARDS:
      return {
        ...state,
        data: action.data.giftCards,
        total: action.data.total,
      };
    case GiftCardsTypes.SET_LOADING_GIFT_CARDS:
      return { ...state, loading: action.data };
    default:
      return state;
  }
};
