import { OperationsTypes } from 'ts/enums/operations'

interface IOperationsReducer {
  data: any[],
  total: number,
  params: {},
  selectedOperation: any,
  types: any[],
  loading: boolean,
  loadingDetail: boolean,
}

const initialState: IOperationsReducer = {
  data: [],
  total: 0,
  params: {},
  selectedOperation: null,
  types: [],
  loading: false,
  loadingDetail: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch (action.type) {
    case OperationsTypes.GET_DATA_OPERATIONS:
      return { ...state, data: action.data, total: action.total, params: action.params }
    case OperationsTypes.GET_OPERATION:
      return { ...state, selectedOperation: action.data }
    case OperationsTypes.GET_TYPES_OPERATION:
      return { ...state, types: action.data }
    case OperationsTypes.SET_LOADING_OPERATION:
      return { ...state, loading: action.data }
    case OperationsTypes.SET_LOADING_DETAIL_OPERATION:
      return { ...state, loadingDetail: action.data }
    case OperationsTypes.APPEND_OPERATION:
      return { ...state, data: [action.data, ...state.data] }
    case OperationsTypes.LOGOUT:
      return initialState
    default:
      return state
  }
}

