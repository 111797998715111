/* eslint-disable @typescript-eslint/no-unused-vars */
// ** React Imports
import React from 'react';
import { Fragment, useState } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import { Eye, EyeOff } from 'react-feather'
import { InputGroup, Input, InputGroupText, Label } from 'reactstrap'

const InputPasswordToggle = (props: any) => {
  // ** Props
  const {
    label,
    hideIcon,
    showIcon,
    visible,
    className,
    htmlFor,
    placeholder,
    iconSize,
    inputClassName,
    ...rest
  } = props

  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible)

  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize ? iconSize : 14

    if (inputVisibility === false) {
      return hideIcon ? hideIcon : <Eye size={size} />
    } else {
      return showIcon ? showIcon : <EyeOff size={size} />
    }
  }

  return (
    <Fragment>
      {label ? <Label for={htmlFor}>{label}</Label> : null}
      <InputGroup
        className={classnames({
          [className]: className
        })}
      >
        <Input
          type={inputVisibility === false ? 'password' : 'text'}
          placeholder={placeholder ? placeholder : '············'}
          className={classnames({
            [inputClassName]: inputClassName
          })}
          /*eslint-disable */
          {...(label && htmlFor
            ? {
              id: htmlFor
            }
            : {})}
          {...rest}
        /*eslint-enable */
        />
        <InputGroupText className='cursor-pointer' onClick={() => setInputVisibility(!inputVisibility)}>
          {renderIcon()}
        </InputGroupText>
      </InputGroup>
    </Fragment>
  )
}

export default InputPasswordToggle


