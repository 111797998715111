import { rootReducer } from './reducers'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'


const middlewares = [thunk]
let compose: any = null

if (process.env.NODE_ENV === 'development') {
  const composeEnhancers = composeWithDevTools({ trace: true })
  compose = composeEnhancers(applyMiddleware(...middlewares))
} else {
  compose = applyMiddleware(...middlewares)
}

export const configureStore = () => createStore(rootReducer, compose)
