import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import * as route from 'utils/routes'
import { isExternalUser } from "utils/helpers"

interface IProps {
  component: any
  exact?: boolean
  path: string
}

export const PrivateRoute =({ component: Component, ...props }: IProps) => {
  const state = useSelector((state: any) => state)

  const { authenticated } = state.user
  function validateAuth() {
    if (authenticated) {
      if (isExternalUser()) {
        if (props.path !== route.operationsPage) {
          return <Redirect to={route.operationsPage} />
        }
        return <Component {...props} />
      }
      return <Component {...props} />
    } else {
      return <Redirect to={route.loginPage} />
    }
  }

  return <Route {...props} render={validateAuth} />
}

export const AuthRoute =({ component: Component, ...props }: IProps) => {
  const state = useSelector((state: any) => state)

  const { authenticated } = state.user
  
  
  function validateAuth() {
    if (!authenticated) {
      return <Component {...props} />
    } else {
      return <Redirect to={isExternalUser() ? route.operationsPage : route.dashboardPage} />
    }
  }

  return <Route {...props} render={validateAuth} />
}