import '@core/scss/react/libs/react-select/_react-select.scss'
import '@core/scss/react/libs/tables/react-dataTable-component.scss'
import '@core/scss/react/libs/toastify/toastify.scss'
import React from 'react'
import 'react-accessible-accordion/dist/fancy-example.css'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { configureStore } from 'store'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import App from './App'
import './styles/index.scss'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <HelmetProvider>
        <ToastContainer
          position='top-right'
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ zIndex: 1000000 }}
        />
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

