import React, { useState } from "react";
import AskCode from "./askCode";
import ShowDetails from "./showDetails";
import { toast } from "react-toastify";

const Claims = () => {
  const [step, setStep] = useState(0);
  const [cardInfo, setCardInfo] = useState(null);

  const onBack = () => {
    setStep(0);
    setCardInfo(null);
    toast.success("Gift Card reclamada con éxito");
  };

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      {step === 0 && <AskCode setStep={setStep} setCardInfo={setCardInfo} />}
      {step === 1 && <ShowDetails cardInfo={cardInfo} onBack={onBack} />}
    </div>
  );
};

export default Claims;
