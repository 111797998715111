export const kyc = {
  WITHOUR_START: {
    value: null,
    label: 'Sin iniciar'
  },
  UNVERIFIED: {
    value: 'unverified',
    label: 'Sin iniciar'
  },
  PENDING : {
    value: 'pending_input',
    label: 'Pendiente'
  },
  DONE: {
    value: 'done',
    label: 'Realizado'
  },
  DENY:{
    value: 'deny',
    label: 'Denegado'
  }
}

export const typeDocument = {
  DNI: {
    value: 1,
    label: 'Documento Nacional de Identidad'
  },
  RUC: {
    value: 2,
    label: 'Registro Único de Contribuyentes'
  },
  CE: {
    value: 3,
    label: 'Carnet de Extranjería'
  },
  DRIVER_LICENSE: {
    value: 4,
    label: 'Licencia de Conducir'
  },
  PASSPORT : {
    value: 5,
    label: 'Pasaporte'
  }
}

export const genders = {
  MALE: {
    value: 'm',
    label: 'Hombre'
  },
  FEMALE: {
    value: 'f',
    label: 'Mujer'
  }
}

export const getKYCStatus = ({status}:any)=>{
  switch (status) {
    case kyc.WITHOUR_START.value:
      return {
        state:'Sin Iniciar',
        style: 'light-secondary'
      }
    case kyc.PENDING.value:
      return {
        state:'Pendiente',
        style: 'light-warning'
      }
    case kyc.DONE.value:
      return {
        state:'Realizado',
        style: 'light-success'
      }
    case kyc.DENY.value:
      return {
        state:'Denegado',
        style: 'light-danger'
      }
    default:
      return {
        state:'Error',
        style: 'light-danger'
      }
  }
}