import { FormGroup, Input, Label } from "reactstrap";
import classnames from "classnames";

export const CustomForm = ({
  field,
  text,
  isRequired = true,
  errors,
  ...inputProps
}) => {
  return (
    <FormGroup>
      <CustomLabel forId={field} isRequired={isRequired}>
        {text}
      </CustomLabel>
      <Input
        {...inputProps}
        name={field}
        id={field}
        className={classnames({ "is-invalid": errors[field] })}
      />
    </FormGroup>
  );
};

export const CustomLabel = ({ forId, children, isRequired }) => {
  return (
    <Label for={forId}>
      {children}
      {isRequired && <span className="text-danger">*</span>}
    </Label>
  );
};
