export const API = process.env.REACT_APP_API || "";

export enum MICROSERVICES {
  MS_USER_PROFILE = "ms-user-profile",
  MS_CAMBIOS = "ms-cambios",
  LAMBDA_ADMIN = "fluyez",
}

export const OBJ_STATUS_GIFT = {
  PENDING: "Pending",
  REDEEMED: "Redeemed",
  DISABLED: "Disabled",
};

export const OBJ_TYPE_GIFT = {
  VIRTUAL: "Virtual",
  PHYSICAL: "Fisico",
};

export const STATUS_GIFT = [
  { value: OBJ_STATUS_GIFT.PENDING, label: OBJ_STATUS_GIFT.PENDING },
  { value: OBJ_STATUS_GIFT.REDEEMED, label: OBJ_STATUS_GIFT.REDEEMED },
  { value: OBJ_STATUS_GIFT.DISABLED, label: OBJ_STATUS_GIFT.DISABLED },
];
export const TYPE_GIFT = [
  { value: OBJ_TYPE_GIFT.VIRTUAL, label: OBJ_TYPE_GIFT.VIRTUAL },
  { value: OBJ_TYPE_GIFT.PHYSICAL, label: OBJ_TYPE_GIFT.PHYSICAL },
];
export const TYPE_USERS = {
  1: "Usuario",
  7: "Ministerio de Justicia", 
  8: "Premium"
}

export const AMOUNT_FEES = {
  '0.001': "0.10%",
  '0.0015': "0.15%",
  '0.002': "0.20%",
  '0.0025': "0.25%",
  '0.003': "0.30%",
  '0.0035': "0.35%",
  '0.004': "0.40%",
  '0.0045': "0.45%",
  '0.005': "0.50%",
  '0.0055': "0.55%",
  '0.006': "0.60%",
  '0.0065': "0.65%",
  '0.007': "0.70%",
  '0.0075': "0.75%",
  '0.008': "0.80%",
  '0.0085': "0.85%",
  '0.009': "0.90%",
  '0.0095': "0.95%",
  '0.01': "1%",
}