import API from "api/api";
import endpoints from "api/endpoints/lambda";
import { CustomForm, CustomLabel } from "components/InputForm";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Col, Input, Form, Row, FormGroup, Label } from "reactstrap";
import { OBJ_STATUS_GIFT } from "utils/constants";
import Select from "react-select";
import { selectThemeColors } from "utils/Utils";
import { typeDocument } from "utils/users";

const details = [
  { label: "Monto", key: "amount" },
  { label: "Moneda", key: "currency" },
  {
    label: "Fecha de expiración",
    key: "expirationDate",
    special: (item) => new Date(item.expirationDate).toLocaleDateString(),
  },
  { label: "Estado", key: "status" },
  { label: "Tipo", key: "type" },
  { label: "Notas", key: "notes" },
];

const virtualDetails = [
  { label: "Email", key: "email" },
  { label: "Nombre completo", key: "fullname" },
  { label: "Tipo de documento", key: "idTypeDocument" },
  { label: "Número de documento", key: "docNumber" },
];

const typeDocumentOptions = () => {
  return [
    typeDocument.DNI,
    typeDocument.RUC,
    typeDocument.CE,
    typeDocument.DRIVER_LICENSE,
    typeDocument.PASSPORT,
  ].map((item) => ({ value: item.label, label: item.label }));
};

const SEND_OPTS = {
  FLUYEZ: "walletFluyez",
  BLOCKCHAIN: "walletBlockchain",
};

const ShowDetails = ({ cardInfo, onBack }) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });
  const [sendTo, setSendTo] = useState("");

  const isVirtual = cardInfo.type === "Virtual";

  const updateGiftCard = async (values) => {
    try {
      const response = await API.request({
        method: "PUT",
        path: `${endpoints.giftCards}/${cardInfo._id}`,
        body: {
          ...cardInfo,
          ...values,
          status: OBJ_STATUS_GIFT.REDEEMED,
          sendTo,
        },
      });
      console.log("response", response);
      if (response?.ok) {
        onBack();
      } else throw new Error();
    } catch (error) {
      console.error("error", error);
      toast.error("Error al reclamar la Gift Card");
    }
  };

  return (
    <div className="container">
      <h1>
        Detalle de la Gift Card con codigo: <b>{cardInfo.code}</b>
      </h1>
      <hr />
      <h3 className="mt-1">
        Estas por reclamar tu Gift Card. Puedes ver la informacion a
        continuacion
      </h3>
      <Row className="mt-2">
        {[...details, ...(isVirtual ? virtualDetails : [])].map((detail) => (
          <Col
            key={detail.key}
            md={6}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <p>{detail.label}</p>
            <p>
              {detail.special ? detail.special(cardInfo) : cardInfo[detail.key]}
            </p>
          </Col>
        ))}
      </Row>
      <hr />
      {isVirtual ? null : <p>Enter your details to claim your gift card.</p>}
      <Form
        onSubmit={handleSubmit(updateGiftCard)}
        className="d-flex flex-column"
      >
        {isVirtual ? null : (
          <Row>
            <Col md={6}>
              <CustomForm
                field="email"
                text="Email"
                isRequired
                errors={errors}
                innerRef={register({ required: true })}
                type="email"
              />
            </Col>
            <Col md={6}>
              <CustomForm
                field="fullname"
                text="Nombre completo"
                isRequired
                errors={errors}
                innerRef={register({ required: true })}
              />
            </Col>
            <Col md={6}>
              <Controller
                name={"idTypeDocument"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Se requiere el tipo de documento",
                  },
                }}
                render={({ onChange, value, name, ref }) => (
                  <div className="mb-1">
                    <CustomLabel forId="idTypeDocument">
                      Tipo de Documento
                    </CustomLabel>
                    <Select
                      theme={selectThemeColors}
                      isClearable={false}
                      className="react-select"
                      classNamePrefix="select"
                      inputRef={ref}
                      options={typeDocumentOptions()}
                      value={typeDocumentOptions().find(
                        (c) => c.value === value
                      )}
                      onChange={(val) => onChange(val.value)}
                    />
                  </div>
                )}
              />
            </Col>
            <Col md={6}>
              <CustomForm
                field="docNumber"
                text="Número de documento"
                isRequired
                errors={errors}
                innerRef={register({ required: true })}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <p>Donde deseas recibir tus {cardInfo.currency}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Input
                type="radio"
                id={SEND_OPTS.FLUYEZ}
                checked={sendTo === SEND_OPTS.FLUYEZ}
                onChange={() => setSendTo(SEND_OPTS.FLUYEZ)}
              />
              <Label className="h5">Fluyez Wallet</Label>
            </FormGroup>
          </Col>
          {sendTo === SEND_OPTS.FLUYEZ ? (
            <Col md={10}>
              <CustomForm
                field="walletEmail"
                text="Ingresa tu correo registrado"
                isRequired
                errors={errors}
                innerRef={register({ required: true })}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Input
                type="radio"
                id={SEND_OPTS.BLOCKCHAIN}
                checked={sendTo === SEND_OPTS.BLOCKCHAIN}
                onChange={() => setSendTo(SEND_OPTS.BLOCKCHAIN)}
              />
              <Label className="h5">Blockchain Wallet</Label>
            </FormGroup>
          </Col>
          {sendTo === SEND_OPTS.BLOCKCHAIN ? (
            <Col md={10}>
              <CustomForm
                field="walletBlockchain"
                text="Ingresa tu dirección de criptomonedas"
                isRequired
                errors={errors}
                innerRef={register({ required: true })}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <div className="d-flex justify-content-end w-100">
            <Button type="submit" color="primary">
              Continue
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default ShowDetails;
