import API from "api/api";
import endpoints from "api/endpoints/lambda";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Form, Input } from "reactstrap";

const AskCode = ({ setStep, setCardInfo }) => {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  const getGiftCardInfo = async (values) => {
    try {
      const response = await API.request({
        method: "GET",
        path: `${endpoints.giftCards}/${values.giftCode}`,
        hasToken: false,
      });
      if (response.ok) {
        setCardInfo(response.data);
        setStep(1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <h1>Claims your gift card</h1>
      <p>Enter your gift card code to claim your gift card.</p>
      <Form
        onSubmit={handleSubmit(getGiftCardInfo)}
        className="d-flex flex-row"
      >
        <Input
          type="text"
          placeholder="Enter your gift card code"
          innerRef={register({ required: true })}
          name="giftCode"
          id="giftCode"
          className="mr-2"
        />
        <Button color="primary">Continue</Button>
      </Form>
    </div>
  );
};

export default AskCode;
