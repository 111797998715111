import { UserTypes } from 'ts/enums/user'

interface IUserReducer {
  authenticated:boolean,
  email: string
}

const initialState:IUserReducer = {
  authenticated: false,
  email: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case UserTypes.SET_AUTH_STATUS:
    return {
      ...state,
      authenticated: action.data.status,
      email: action.data.email
    }
  case UserTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}

