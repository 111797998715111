import * as configActions from 'actions/config'
import * as userActions from 'actions/user'
import API from 'api/api'
import endpoints from 'api/endpoints/lambda'
import { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Routes from 'routes'
import { getValueLocalStorage } from 'utils/storage'

function App() {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  
  async function validateAuth() {
    try {
      if(getValueLocalStorage('token') === null) return

      const { response } = await API.request({
        path: endpoints.validateToken,
        method: 'POST',
        hasToken: true,
      })

      if(!response.success) throw new Error('Invalid token')

      dispatch(configActions.listConfig())
      dispatch(userActions.setAuthenticated(true, response.data.email))
    } catch {
      dispatch(userActions.logout())
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    validateAuth()
  }, [])

  return (
    loading ? <div /> : <Routes />
  )
}

export default App;
