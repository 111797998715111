import { ConfigTypes } from 'ts/enums/config'

interface IConfigReducer {
  countries:any[],
  regimens: any[],
  occupations: any[],
  commercialSectors: any[],
  maritalStatus: any[],
  commissions?: any[],
}

const initialState:IConfigReducer = {
  countries: [],
  regimens: [],
  occupations: [],
  commercialSectors: [],
  maritalStatus: [],
  commissions: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case ConfigTypes.GET_COMMISSIONS:
    return {...state, commissions: action.data}
  case ConfigTypes.GET_COUNTRIES:
    return {...state, countries: action.data}
  case ConfigTypes.GET_REGIMENS:
    return {...state, regimens: action.data}
  case ConfigTypes.GET_OCCUPATIONS:
    return {...state, occupations: action.data}
  case ConfigTypes.GET_COMMERCIAL_SECTORS:
    return {...state, commercialSectors: action.data}
  case ConfigTypes.GET_MARITAL_STATUS:
    return {...state, maritalStatus: action.data}
  case ConfigTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}