import Login from "pages/login";
import { Suspense, lazy } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import * as route from "utils/routes";
import { AuthRoute, PrivateRoute } from "./config";
import Claims from "pages/claims";

const Dashboard = lazy(() => import("pages/dashboard"));
const UsersList = lazy(() => import("pages/users/list"));
const UsersDetails = lazy(() => import("pages/users/details"));
const OperationsList = lazy(() => import("pages/operations/list"));
const GiftsList = lazy(() => import("pages/gifts/list"));

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute exact path={route.loginPage} component={Login} />

        <Route
          exact
          path={[
            route.dashboardPage,
            route.usersPage,
            route.userPage,
            route.operationsPage,
            route.giftsPage,
          ]}
        >
          <Suspense fallback={<div />}>
            <Switch>
              <PrivateRoute
                exact
                path={route.dashboardPage}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path={route.usersPage}
                component={UsersList}
              />
              <PrivateRoute
                exact
                path={route.userPage}
                component={UsersDetails}
              />
              <PrivateRoute
                exact
                path={route.operationsPage}
                component={OperationsList}
              />
              <PrivateRoute
                exact
                path={route.giftsPage}
                component={GiftsList}
              />
            </Switch>
          </Suspense>
        </Route>
        <Route path={route.claimPage} component={() => <Claims />} />
        <Route path="*" component={() => <Redirect to={route.loginPage} />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
