/* eslint-disable */
import BlankLayout from '@core/layouts/BlankLayout'
import '@core/scss/base/pages/page-auth.scss'
import * as configActions from 'actions/config'
import * as userActions from 'actions/user'
import InputPasswordToggle from 'components/InputPasswordToogle'
import useQuery from 'hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap'
import { handleLogin } from './services'

function Login() {
  const querys = useQuery()
  const [form, setForm] = useState({
    email: '',
    password: ''
  })
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const onSubmitLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const callback = (email:string) => {
      dispatch(configActions.listConfig())
      dispatch(userActions.setAuthenticated(true, email))
    }
  
    handleLogin({
      setLoading,
      form,
      callback,
    })
  }

  useEffect(() => {
    if(querys.get('expired') === '1') {
      toast.error('Su sesión ha expirado')
    }
  }, [])

  return (
    <BlankLayout>
      <div className='auth-wrapper auth-v1 px-2'>
        <div className='auth-inner py-2'>
          <Card className='mb-0'>
            <CardBody>
              <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                <h2 className='brand-text text-primary'>Fluyez</h2>
              </Link>
              <CardTitle tag='h4' className='mb-1'>
                Fluyez Admin
              </CardTitle>
              <Form className='auth-login-form mt-2'>
                <FormGroup>
                  <Label className='form-label' for='login-email'>
                    Email
                  </Label>
                  <Input type='email' name='email' id='login-email' placeholder='john@example.com' autoFocus onChange={onChange} />
                </FormGroup>
                <FormGroup>
                  <div className='d-flex justify-content-between'>
                    <Label className='form-label' for='login-password'>
                      Contraseña
                    </Label>
                  </div>
                  <InputPasswordToggle name='password' className='input-group-merge' id='login-password' onChange={onChange} visible={false} />
                </FormGroup>
                <Button color='primary' block onClick={onSubmitLogin} disabled={loading}>
                  Iniciar Sesión
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </BlankLayout>
  )
}

export default Login
