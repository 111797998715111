import {CoinsTypes} from 'ts/enums/coins'

interface ICoinsReducer {
  allData:any[],
  data: any[],
  total: number,
  params:{},
  selectedOperation: any,
}

const initialState:ICoinsReducer = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedOperation: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case CoinsTypes.GET_ALL_DATA_COINS:
    return {...state, allData: action.data}
  case CoinsTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}