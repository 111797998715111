import { UserTypes } from 'ts/enums/user'
import { removeValueLocalStorage } from 'utils/storage'

export const setAuthenticated = (status: boolean, email?:string) => async (dispatch: any) => {
  dispatch({
    type: UserTypes.SET_AUTH_STATUS,
    data: {
      status,
      email
    }
  })
}

export const logout = () => async (dispatch: any) => {
  await removeValueLocalStorage('token')
  dispatch({
    type: UserTypes.LOGOUT,
  })
}