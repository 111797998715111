import { combineReducers } from "redux";
import coins from "./coins";
import config from "./config";
import dashboard from "./dashboard";
import layout from "./layout";
import notes from "./note";
import operations from "./operations";
import user from "./user";
import users from "./users";
import giftCards from "./giftCards";

export const rootReducer = combineReducers({
  user,
  users,
  dashboard,
  giftCards,
  layout,
  operations,
  coins,
  config,
  notes,
});
