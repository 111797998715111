import {UsersTypes} from 'ts/enums/users'

interface IUsersReducer {
  allData:any[],
  data: any[],
  total: number,
  params:{},
  selectedUser: any,
  loading: boolean
}

const initialState:IUsersReducer = {
  allData: [],
  data: [],
  total: 0,
  params: {},
  selectedUser: null,
  loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case UsersTypes.GET_ALL_DATA_USERS:
    return {...state, allData: action.data}
  case UsersTypes.GET_DATA_USERS:
    return {...state, data: action.data, total: action.total, params: action.params}
  case UsersTypes.GET_USER:
    return {...state, selectedUser: action.data}
  case UsersTypes.SET_LOADING_USER:
    return {...state, loading: action.data}
  case UsersTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}

