import jwt_decode from 'jwt-decode'

export const isExternalUser = () => {
  const token: any = localStorage.getItem('token')
  const { permissions }: any = jwt_decode(token)
  const allowedPermissions = ['BODEGABITCOIN', 'DOLARBIT', 'VERSO']
  const result = allowedPermissions.some((permission: string) => permissions.includes(permission))
  return result
}

export const getRolePermissions = () => {
  const token: any = localStorage.getItem('token')
  const { permissions }: any = jwt_decode(token)
  return permissions
}