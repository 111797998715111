export function getValueLocalStorage(name: string) {
  return window.localStorage.getItem(name)
}

export function updateValueLocalStorage(name: string, value: any) {
  return window.localStorage.setItem(name, value)
}

export function removeValueLocalStorage(name: string) {
  return window.localStorage.removeItem(name)
}