import { DashboardTypes } from 'ts/enums/dashboard'

interface IUserReducer {
  data: any,
  params: any,
  loading:boolean,
  balancePerCoin: any,
  volumesOfOperations: any,
  volumeOfOperationsPerCoin:any,
}

const initialState:IUserReducer = {
  data: {},
  params: {},
  loading:false,
  balancePerCoin: null,
  volumesOfOperations: null,
  volumeOfOperationsPerCoin: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch(action.type){
  case DashboardTypes.SET_DASHBOARD:
    return {
      ...state,
      data: action.data,
      params: action.params,
    }
  
  case DashboardTypes.SET_BALANCE_PER_COIN:
    return {
      ...state,
      balancePerCoin: action.data,
    }

  case DashboardTypes.SET_VOLUME_OF_OPERATIONS:
    return {
      ...state,
      volumesOfOperations: action.data,
    }

  case DashboardTypes.SET_VOLUME_OF_OPERATIONS_PER_COIN:
    return {
      ...state,
      volumeOfOperationsPerCoin: action.data,
    }

  case DashboardTypes.SET_LOADING:
    return {...state,loading: action.data}
  case DashboardTypes.LOGOUT:
    return initialState
  default:
    return state
  }
}