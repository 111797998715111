import API from 'api/api'
import endpoints from 'api/endpoints/lambda'
import { toast } from 'react-toastify'
import { updateValueLocalStorage } from 'utils/storage'
import { IHandleLogin } from './props'

export const handleLogin = async ({
  setLoading,
  form,
  callback
}: IHandleLogin) => {
  try{
    setLoading(true)

    const { response } = await API.request({
      path: endpoints.login,
      method: 'POST',
      body: form
    })

    if(!response?.success) throw new Error(response?.error)

    updateValueLocalStorage('token', response.data.accessToken.token)
    callback(response.data.accessToken.email)
  }catch(err: any) {
    toast.error(`${err.message || 'Usuario y/o Contraseña incorrecta'}`)
  }finally{
    setLoading(false)
  }
}